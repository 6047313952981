import { Collapse } from "bootstrap";

const Common = {
  /**
   * 通知の既読処理を行って表示を既読にする
   *
   */
  updateNotificationUnread() {
    $.ajax({
      url: "/update_unread",
      type: "PUT",
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
    });
    const notificationBadge = document.querySelector(".notification_badge");
    if (notificationBadge != null) {
      notificationBadge.style.display = "none";
    }
  },

  /**
   * 通知リスト・マイページメニューを閉じる
   *
   */
  controllHeaderCollapse() {
    const notificationList = document.getElementById("notificationList");
    const myMenu = document.getElementById("mypageMenu");

    window.onclick = (event) => {
      // 通知リストが開いている場合、通知リスト以外の箇所をクリックすると閉じる
      if (
        notificationList.classList.contains("show") &&
        event.target != notificationList
      ) {
        const notificationCollapse = Collapse.getInstance(notificationList);
        notificationCollapse.hide();
      }
      // マイページメニューが開いている場合、マイページメニュー以外の箇所をクリックすると閉じる
      if (myMenu.classList.contains("show") && event.target != myMenu) {
        const myMenuCollapse = Collapse.getInstance(myMenu);
        myMenuCollapse.hide();
      }
    };
  },

  // スマホ用アプリお知らせバナーの制御
  controlSpHeadAppBnr() {
    let btnClose = document.querySelector('.js_sp_headAppBnr .btn_close');

    if (localStorage.getItem('hideHeadAppBanner') !== '1') {
      let header = document.querySelector('header');
      header.classList.add('is_headAppBnr');

      // xボタンでバナーを閉じ、その後は表示しないように状態を保持しておく
      btnClose.addEventListener('click', function () {
        localStorage.setItem('hideHeadAppBanner', '1');
        header.classList.remove('is_headAppBnr');
      });
    }
  }
};

document.addEventListener("DOMContentLoaded", () => {
  // ページロード後にグローバル定数にCommonを追加する
  Object.defineProperty(globalThis, "Common", {
    value: Common,
    writable: false,
  });
  Common.controllHeaderCollapse();

  if (window.matchMedia('(max-width: 767px)').matches) {
    Common.controlSpHeadAppBnr();
  }
});

$(function() {
  //ヘッダー関連の動作

  //検索関連の動作
  const selectPlace = $("#selectPlace");
  const seletDate = $("#seletDate");
  const selectEtc = $("#selectEtc");

  function reset_selectPlace() {
    selectPlace.collapse("hide");
  }
  function reset_seletDate() {
    seletDate.collapse("hide");
  }
  function reset_selectEtc() {
    selectEtc.collapse("hide");
  }

  //場所を選ぶを開いた時
  selectPlace.on("shown.bs.collapse", function() {
    //本体以外をクリックしたら閉じる
    $(document).on("click touchend", function(e) {
      if (!$(e.target).closest("#selectPlace").length) {
        reset_selectPlace();
      }
    });
  });
  //日付を選ぶを開いた時
  seletDate.on("shown.bs.collapse", function() {
    //本体以外をクリックしたら閉じる
    $(document).on("click touchend", function(e) {
      if (!$(e.target).closest("#seletDate").length) {
        reset_seletDate();
      }
    });
  });
  //その他条件を開いた時
  selectEtc.on("shown.bs.collapse", function() {
    //本体以外をクリックしたら閉じる
    $(document).on("click touchend", function(e) {
      if (!$(e.target).closest("#selectEtc").length) {
        reset_selectEtc();
      }
    });
  });

  // ページ最下部までスクロール時にスマホ検索ボタンの位置調整
  $(window).on("scroll", function() {
    const docHeight = $(document).innerHeight(); //ドキュメントの高さ
    const windowHeight = $(window).outerHeight(); //ウィンドウの高さ
    const pageBottom = docHeight - windowHeight;
    const scroll = $(window).scrollTop() + 10;

    if (pageBottom <= scroll) {
      //一番下までスクロールした時に実行
      $("#spSearchBtn").addClass("is_bottom");
    } else {
      $("#spSearchBtn").removeClass("is_bottom");
    }
  });
});
